.wrapper{
    display: flex;
    width: 100%;
    color: white;
    margin: 40px;
    gap: 50px;
    @media screen and (max-width: 800px) {
      margin: 40px 0px;
      }

      @media screen and (max-width: 1230px) {
        flex-direction: column;
        }
  }
  
.tittle{
  background-color: white;
  color: black;
  text-transform: uppercase;
    width: fit-content;
    font-size: 48px;
    padding: 0 10px;
    font-weight: bolder;
    margin-bottom: 10px;
    @media screen and (max-width: 850px) {
        font-size: 42px;
      }
      @media screen and (max-width: 405px) {
        font-size: 24px;
      }
}

.words{
  width: 60%;    
  @media screen and (max-width: 1230px) {
    width: 90%;
    padding: 0 10px;
    }
} 
.text{
  padding: 20px 0;
}

.logo{
  display: flex;
  width: 50%;
  @media screen and (max-width: 1230px) {
    justify-content: center;
    width: 100%;

    }
}

.logo img{
  width: 100%;
  @media screen and (max-width: 1230px) {
    width: 100%;

    }
    @media screen and (max-width: 555px) {
      width: 100%;
      }
}
