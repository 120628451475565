.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(43,6,43);
}

.loading-text {
  display: flex;
  align-items: center;
  text-align: center;
  justify-self: center;
  margin-top: 20px;
  font-size: 1.5rem;
  color: #ffffff; /* Измените цвет текста по вашему желанию */
}