.wrapper {
  display: flex;
  width: 95%;
  color: white;
  margin: 40px;
  padding: 0 10px;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    margin: 40px 0px;
  }

  @media screen and (max-width: 1230px) {
    flex-direction: column;
  }
}

.title {
  background-color: white;
  width: fit-content;
  color: black;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: bolder;
  margin-bottom: 30px;

  @media screen and (max-width: 850px) {
      font-size: 42px;
  }

  @media screen and (max-width: 405px) {
      font-size: 24px;
  }
}

.map {
  width: 100%;
  margin-bottom: 30px;
  margin: 0 auto;
}

.map img{
  margin: 0 auto;
  width: 100%;
}

.descriptionTitle {
  font-size: 32px;
  font-weight: bolder;
  margin-bottom: 10px;
  @media screen and (max-width: 850px) {
    font-size: 42px;
}

@media screen and (max-width: 405px) {
    font-size: 24px;
}
}
