.wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: url("../../../../public/images/new.jpg") no-repeat;
    color: rgb(0, 0, 0);
    justify-content: center;
    height:500px;
    margin: 40px;
    font-family: "Archivo Black", sans-serif;
  }
  
  .inner{
    margin: 20px;
    z-index: 20;
    
  }
.tittle{
    text-transform: uppercase;
    background-color: white;
    width: 70%;
    padding: 10px;
    font-size: 48px;
    font-weight: bolder;
    margin-bottom: 10px;
    @media screen and (max-width:1313px) {
        font-size: 40px;
        width: 90%;
      }
      @media screen and (max-width: 805px) {
        text-align: center;
        margin: 20px auto;
        align-items: center;
        justify-content: center;
        font-size: 32px;
      }
      @media screen and (max-width: 405px) {
        font-size: 24px;
      }
}


.subTittle{
  color: white;
    width: 30%;
    margin-bottom: 10px;
    @media screen and (max-width: 805px) {
      text-align: center;
      align-items: center;
      justify-content: center;
        font-size: 12px;
        width: 100%;
      }
}

.buttons{
  font-family: "Archivo Black", sans-serif;
  text-transform: uppercase;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 20px 0;
    @media screen and (max-width: 805px) {
      align-items: center;
      justify-content: center;
      }
}

.learn {
  text-transform: uppercase;

  font-family: "Archivo Black", sans-serif;
  cursor: pointer;

  background-color: rgb(246,146,30);
color: white;


  padding: 10px 40px;
  transition: 0.3s;
  &:hover{
      background-color: rgb(246,146,30);
      background-color: rgb(218, 130, 29);


  }


}

button{
  border: none;
}
.submit {
  text-transform: uppercase;

  font-family: "Archivo Black", sans-serif;

  cursor: pointer;
  background-color: rgb(246,146,30);

color: white;
  padding: 10px 40px;
  transition: 0.3s;
  &:hover{
  background-color: rgb(218, 130, 29);

  }
}