.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  margin: 40px;
}


.title {
  display: flex;
  color: black;
  width: fit-content;
  background-color: white;
  justify-content: center;
  font-size: 48px;
  font-weight: bolder;
  margin: 10px 0;

  @media screen and (max-width: 850px) {
      font-size: 42px;
      text-align: center;
      width: 100%;
  }
  @media screen and (max-width: 405px) {
      font-size: 24px;

      width: 100%;
  }
}

.subTitle{
  margin-bottom: 80px;
}

.faq {
  margin-bottom: 50px;
}

.question {
  position: relative; 
  font-size: large;
  font-weight: bolder;
}

.answer{
  margin-top: 30px;
  display: flex;
  width: 70%;
  @media screen and (max-width: 405px) {
    width: 100%;
}
}

.question::before {
  content: ""; 
  position: absolute;
  top: -20px; 
  left: 0; 
  width: 100%;
  height: 2px; 
  background-color: #ffffff; 
}