.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0;
    opacity: 0;
    visibility: hidden; /* Ensure the menu is hidden when not active */
    z-index: -1;
    transition: all 0.5s;
}

.menu.active {
    opacity: 1;
    visibility: visible; /* Ensure the menu is visible when active */
    z-index: 0;
}

.menuContent {
    width: 100%;
    height: 100%;
    background-color:rgba(75, 30, 75, 0.96);
    display: flex;
}

ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;
    margin: 0 auto;
}

ul li {
    padding: 15px 0px;
    list-style-type: none;
}

ul li a {
    font-family: "Noto Sans JP", sans-serif;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}
