

.wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px 0 0 0;

}

.text {
  padding: 30px 0 30px 0;
  font-size: 18px;
  color: #ffffff;
}

.logo {
  display: flex;
  width: 150px;
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 890px) {
    margin-bottom: 40px;
    width: 100px;
  }
}

.rows {
  padding: 10px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 890px) {
    flex-direction: column;
  }
}

.row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: fit-content;
  height: auto;
  font-size: 15px;

  a {
    margin-bottom: 15px;
  }
}

a {
  color: #ffffff;
  text-decoration: none;
}

.button {
  width: 250px;
  margin: 10px auto 0 auto;
  transition: all 0.5s ease-out;

  @media screen and (max-width: 890px) {
    width: 200px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    transform: scale(1.04);
  }
}

.line {
  height: 1px;
  background-color: #000000;
  margin: 10px 0;
}

.copyright {
  padding: 10px;
  font-size: 14px;
}