.wrapper {
    justify-content: space-between;
    font-family: "Archivo Black", sans-serif;

}

.inner{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.title {
    background-color: white;
    font-family: "Archivo Black", sans-serif;

    text-transform: uppercase;

    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;

    padding: 20px;

    @media screen and (max-width: 571px) {
        font-size: 30px;
    }

}


.button {
    box-sizing: border-box;
    margin: 20px auto;
    height: 130px;
    transition: all 0.5s ease-out;

    img {
        width: 100%;
        height: 100%;
    }

    &:hover {
        transform: scale(1.1);
    }

    @media screen and (max-width: 571px) {
        width: 80%;
        height: 75px;
    }
}