.wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: url("../../../../public/images/back (1).png") no-repeat;
    background-size: cover;
    justify-content: center;
    height: 70vh;
    font-family: "Archivo Black", sans-serif;

  }
  
.tittle{
    background-color: rgba(255, 255, 255, 0.8); /* Start with transparent background */
    width: 100%;
    font-size: 34px;
    font-weight: bolder;
    padding: 10px;
    text-transform:uppercase;
    margin: 10px;
    @media screen and (max-width: 1500px) {
        font-size: 32px;
        width: 80%;
      }
      @media screen and (max-width: 700px) {
        font-size: 28px;
        text-align: center;
        margin: 0 auto;

      }
}

b{
    color:rgba(140, 58, 140, 1);
}
.subTittle{
    background-color: rgb(246,146,30);
    margin: 10px;
    padding: 10px;
    color: white;
    text-transform: uppercase;
    width: fit-content;
    @media screen and (max-width: 992px) {
        font-size: 12px;
      }
      @media screen and (max-width: 700px) {
        text-align: center;
        margin: 10px auto;

      }
}



.loogo{
    width: 200px;
}