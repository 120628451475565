.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensures the header stays on top of other elements */
    height: 90px;
    display: flex;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.0); /* Start with transparent background */
    transition: background-color 0.3s ease; /* Smooth transition */
}

.wrapper.scrolled {
    background-color: rgba(0, 0, 0, 0.2); /* Dark background on scroll */
}

.inner {
    color: white;
    margin: 0 10px;
    font-family: "Archivo Black", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.logo {
    display: flex;
    gap: 50px;
    align-items: center;
}

.logo img {
    border-radius: 50%;
    width: 90px;
}

.headerItems {
    display: flex;
    width: auto;
    gap: 35px;
    justify-content: space-between;
    @media screen and (max-width: 850px) {
        display: none;
    }
}

a {
    color: white;
    text-decoration: none;
    transition: 0.2s all;
    &:hover {
        color: rgb(177, 177, 177);
    }
}

.button {
    @media screen and (max-width: 850px) {
        display: none;
    }
}

.mobileMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.burgerButton {
    cursor: pointer;
    width: 30px;
    height: 24px; /* Adjusted height to make the click area larger */
    margin-left: 20px;
    position: relative;
    display: flex;
    right: 20px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 850px) {
        display: flex;
    }
}

.burgerButton span,
.burgerButton::before,
.burgerButton::after {
    content: '';
    position: absolute;
    display: none;
    width: 30px; /* Adjusted width */
    height: 3px;
    background-color: white;
    transition: transform 0.3s ease, opacity 0.3s ease;
    @media screen and (max-width: 850px) {
        display: block;
    }
}

.burgerButton::before {
    top: 4px; /* Adjusted to bring closer to the middle span */
}

.burgerButton::after {
    bottom: 3px; /* Adjusted to bring closer to the middle span */
}

.burgerButton.active span {
    opacity: 0;
}

.burgerButton.active::before {
    transform: translateY(4px) rotate(45deg);
}

.burgerButton.active::after {
    transform: translateY(-10px) rotate(-45deg);
}
