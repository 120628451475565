.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  margin: 40px;
}


.title {
  background-color: white;
  display: flex;
  justify-content: center;
  color: black;
  font-size: 48px;
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 10px;
  @media screen and (max-width: 850px) {
      font-size: 42px;
      text-align: center;
      width: 100%;
  }
  @media screen and (max-width: 405px) {
      font-size: 24px;

      width: 100%;
  }
}
.awards {
  margin: 50px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  gap: 40px;

}
.award {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  @media screen and (max-width: 500px) {
    width: 100%;
}
}
.image {

  width: 100px;
  height: auto;
  
}
.text {
  margin-top: 20px;
  text-align: center;
}
