.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  margin: 40px;
}


.title {
  background-color: white;
  color: black;
  width: fit-content;
  display: flex;
  justify-content: center;
  font-size: 48px;
  font-weight: bolder;
  margin: 10px auto;
  @media screen and (max-width: 850px) {
      font-size: 42px;
      text-align: center;
      width: 100%;
  }
  @media screen and (max-width: 405px) {
      font-size: 24px;

      width: 100%;
  }
}

.subTitle{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.mail{
  display: flex;
  justify-content: center;
  padding: 15px;
  border:1px solid white ;
}
